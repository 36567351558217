<template>
  <div>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-else class="mt-6">
      <vs-row class="mb-3">
        <h3>{{ category[`name_${$i18n.locale}`] }}</h3>
        <router-link v-if="$acl.check('admin')" :to="{name: 'categoryEdit', params: {id: $route.params.id}}" class="p-3  mb-4 mr-4 ml-auto rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
          <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base text-primary">{{$t('edit')}}</span>
        </router-link>
        <div v-if="$acl.check('admin')" class="btn-delete p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteData(category.uuid)">
          <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base">{{$t('delete')}}</span>
        </div>
      </vs-row>
      <vs-alert color="danger" class="mb-4" :active.sync="isActive">
        <span>{{ $t('category') }} {{ $t('isNotActive') }}. </span>
      </vs-alert>
      <vs-table stripe :data="Object.keys(category)">
        <template slot="thead">
          <vs-th>{{$t('property')}}</vs-th>
          <vs-th>{{$t('value')}}</vs-th>
        </template>
        <template>
          <vs-tr v-if="category.image_isAdded">
            <vs-td class="uppercase font-medium">{{ $t('props.image') }}</vs-td>
            <vs-td>
              <div class="flex flex-row overflow-x-auto" style="width: 100%;">
                <div class="img-container relative">
                  <img :src="`${$baseUrl}/categories/${category.uuid}.webp`" alt="" class="h-40">
                </div>
              </div>
            </vs-td>
          </vs-tr>
          <vs-tr v-for="(prop, idx) in Object.keys(category)" :key="idx">
            <vs-td>
              <span class="uppercase font-medium">{{$t(`props.${prop}`)}}</span>
            </vs-td>
            <vs-td v-if="category[prop] === true || category[prop] === false">
              <vs-chip :color="category[prop] ? 'success' : 'danger'">
                <feather-icon v-if="category[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td v-else-if="prop === 'category_brands'">
              <router-link v-for="brand in category.category_brands" :key="brand.id" :to="`/brands/${brand.uuid}`"  class="font-medium">{{ brand[`name_${$i18n.locale}`] }}, </router-link>
            </vs-td>
            <vs-td v-else-if="prop === 'createdAt' || prop === 'updatedAt'">
              {{ new Date(category[prop]).toLocaleString('ru-RU') }}
            </vs-td>
            <vs-td v-else-if="prop === 'type'">
              {{ $t(category[prop]) }}
            </vs-td>
            <vs-td v-else>
              {{ category[prop] }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
export default {
  data () {
    return { category: {}, isLoading: true }
  },
  computed: { isActive () { return !this.category.isActive } },
  async created () { await this.fetchData() },
  methods: {
    async fetchData () {
      await this.$http.get(`/categories/${this.$route.params.id}`).then(response => {
        this.category = response.data
        this.isLoading = false
      }).catch((err) => {
        this.notify('Error', this.$t('notify.error'), err.response.data.message)
        this.isLoading = false
      })
    },
    async deleteData (uuid) {
      await this.$http.delete(`/categories/delete/${uuid}`).then(() => {
        this.notify('Success', this.$t('notify.success'), `${this.$t('category')} ${this.$t('notify.sDeleted')}`)
        this.$router.push('/categories')
      }).catch(err => {
        this.notify('Error', this.$t('notify.error'), err.response.data.message)
      })
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'Success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'Success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>